<template>
  <component
    :is="pageComponent"
  />
</template>

<script setup lang="ts">
const dynamicPages = {
  '404': defineAsyncComponent(() => import('~layers/app/pages/~404.vue')),
  'plp': defineAsyncComponent(() => import('~layers/app/pages/~plp.vue')),
  'pdp': defineAsyncComponent(() => import('~layers/app/pages/~pdp.vue')),
  'cms': defineAsyncComponent(() => import('~layers/app/pages/~cms.vue')),
  'customer-service': defineAsyncComponent(() => import('~layers/app/pages/~customer-service.vue')),
  'blog-overview': defineAsyncComponent(() => import('~layers/app/pages/~blog-overview.vue')),
  'blog-detail': defineAsyncComponent(() => import('~layers/app/pages/~blog-detail.vue')),
}

const typeToPageMapping = {
  'plp': 'plp',
  'plp-theme': 'plp',
  'plp-brand': 'plp',
  'plp-filter': 'plp',
  'plp-landing': 'cms',
  'pdp': 'pdp',
  'cms-page': 'cms',
  'customer-service-page': 'customer-service',
  'blog-overview': 'blog-overview',
  'blog-detail': 'blog-detail',
}

const route = useRoute()
const { fetchRoute } = useCustomRouter()
const { fetchRedirect, fetchPdpRedirect } = useCustomRouterRedirect()

const esUrlPath = computed(() => route.path.replace(/^\/+|\/+$/g, ''))
// console.log({ esUrlPath: esUrlPath.value })

const meta = await fetchRoute(route.path)
// console.log({ meta })

provide('meta', ref(meta))

// Check redirects for pdp
if (!meta && route.path.endsWith('.html')) {
  const redirectUrl = await fetchPdpRedirect(route.path.replace(/^\/+/, ''))
  console.log({ route, redirectUrl })

  if (redirectUrl && redirectUrl !== route.path) {
    console.log(`redirect to: ${redirectUrl}`)
    navigateTo(redirectUrl, { redirectCode: 301 })
  }
}

// Resolve page/component to render
const page = computed(() => {
  return typeToPageMapping[meta?.page_type]
})

// Resolve page/component to render
const pageComponent = computed(() => {
  return dynamicPages?.[page.value] || dynamicPages?.['404']
})

// No meta data, or unknown component, check for redirect
if (!meta || !page.value) {
  const redirect = await fetchRedirect(esUrlPath.value)
  console.log({ redirect })
  if (redirect?.relative_url) {
    console.log(`redirect to: ${redirect?.relative_url}`)
    navigateTo(redirect?.relative_url, {
      redirectCode: redirect?.redirectCode || 301,
    })
  }
}

// No redirect, render 404
if (!page.value) {
  // throw createError({
  //   statusCode: 404,
  //   message: 'Page Not Found',
  //   fatal: true,
  // })

  const event = useRequestEvent()

  if (event) {
    setResponseStatus(event, 404, 'Page Not Found')
  }
}
</script>
